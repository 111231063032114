.draftJsEmojiPlugin__alignmentTool__2mkQr {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
          transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
  z-index: 2;
  box-sizing: border-box;
}

.draftJsEmojiPlugin__alignmentTool__2mkQr:after, .draftJsEmojiPlugin__alignmentTool__2mkQr:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.draftJsEmojiPlugin__alignmentTool__2mkQr:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.draftJsEmojiPlugin__alignmentTool__2mkQr:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}
.draftJsEmojiPlugin__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsEmojiPlugin__button__qi1gf {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsEmojiPlugin__button__qi1gf svg {
  fill: #888;
}

.draftJsEmojiPlugin__button__qi1gf:hover, .draftJsEmojiPlugin__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0; /* reset for :focus */
}

.draftJsEmojiPlugin__active__3qcpF {
  background: #efefef;
  color: #444;
}

.draftJsEmojiPlugin__active__3qcpF svg {
  fill: #444;
}

.draftJsEmojiPlugin__image__192TI {}

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsToolbar__button__qi1gf {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsToolbar__button__qi1gf svg {
  fill: #888;
}

.draftJsToolbar__button__qi1gf:hover, .draftJsToolbar__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0; /* reset for :focus */
}

.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444;
}

.draftJsToolbar__active__3qcpF svg {
  fill: #444;
}
.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}
.draftJsToolbar__toolbar__dNtBH {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
  z-index: 2;
  box-sizing: border-box;
}

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.draftJsToolbar__toolbar__dNtBH:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}

.draftJsEmojiPlugin__emojiSelect__34S1B {
  display: inline-block;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  margin: 0;
  padding: 0;
  width: 2.5em;
  height: 1.5em;
  box-sizing: border-box;
  line-height: 1.2em;
  font-size: 1.5em;
  color: #888;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 1.5em;
  cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:focus, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:focus {
  outline: 0;
  /* reset for :focus */
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:hover, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:hover {
  background: #f3f3f3;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:active, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:active {
  background: #e6e6e6;
}

.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  background: #ededed;
}

.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  margin-top: 10px;
  padding: 0 .3em;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
}

.draftJsEmojiPlugin__emojiSelectPopoverClosed__3Kxxq {
  display: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverTitle__3tpXz {
  margin: 0 0 .3em;
  padding-left: 1em;
  height: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
  margin: 0 0 .3em;
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE {
  padding: 0 .5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE:first-child .draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
  display: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
  margin: 1em 0;
  padding-left: .5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupList__HQ8_y {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupItem__2pFOS {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelect__28bny {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectList__haFSJ {
  margin: .3em;
  padding: .3em;
  position: absolute;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: .5em;
  background: #fff;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL:first-child {
  border-right: 1px solid #e0e0e0;
}

.draftJsEmojiPlugin__emojiSelectPopoverEntry__1ErDJ, .draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  background-color: #efefef;
}

.draftJsEmojiPlugin__emojiSelectPopoverEntryIcon__1yNaC {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.draftJsEmojiPlugin__emojiSelectPopoverNav__1Nzd7 {
  margin: 0;
  padding: 0 .5em;
  display: flex;
  width: 20em;
  list-style: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavItem__qydCX {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavEntry__1OiGB, .draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
  color: #42a5f5;
}

.draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
  position: absolute;
  right: 0;
  top: .3em;
  bottom: .3em;
  width: .25em;
  background-color: #e0e0e0;
  border-radius: .125em;
  opacity: .1;
  transition: opacity .4s;
}

.draftJsEmojiPlugin__emojiSelectPopoverScrollbarThumb__jGYdG {
  background-color: #000;
  border-radius: .125em;
  cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m:hover .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
  opacity: .3;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:hover,
.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:active {
  opacity: .6;
}
.draftJsEmojiPlugin__emoji__2oqBk {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;

  /*
  We need to limit the emoji width because it can be multiple characters
  long if it is a 32bit unicode. Since the proper width depends on the font and
  it's relationship between 0 and other characters it's not ideal. 1.95ch is not
  the best value, but hopefully a good enough approximation for most fonts.
  */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  line-height: inherit;
  margin: -.2ex 0em .2ex;
  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;

  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em;
}
.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_ {
  padding: 5px 10px 1px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_:active {
  background-color: #cce7ff;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
  background-color: #e6f3ff;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryText__2sPjk {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryIcon__1qC2V {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: inline-block;
}
.draftJsEmojiPlugin__emojiSuggestions__2ffcV {
  border: 1px solid #eee;
  margin-top: 1.75em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.draftJsLinkPlugin__link__2ittM, .draftJsLinkPlugin__link__2ittM:visited {
  color: #5e93c5;
  text-decoration: none;
}

.draftJsLinkPlugin__link__2ittM:hover, .draftJsLinkPlugin__link__2ittM:focus {
  color: #7eadda;
  outline: 0; /* reset for :focus */
  cursor: pointer;
}

.draftJsLinkPlugin__link__2ittM:active {
  color: #4a7bab;
}

.bpsgbes{display:inline-block;}
.b181v2oy{background:#fbfbfb;color:#888;font-size:18px;border:0;padding-top:5px;vertical-align:bottom;height:34px;width:36px;}.b181v2oy:hover,.b181v2oy:focus{background:#f3f3f3;outline:0;}.b181v2oy svg{fill:#888;}
.a9immln{background:#efefef;color:#444;}.a9immln svg{fill:#444;}
.tukdd6b{left:50%;-webkit-transform:translate(-50%) scale(0);transform:translate(-50%) scale(0);position:absolute;border:1px solid #ddd;background:#fff;border-radius:2px;box-shadow:0px 1px 3px 0px rgba(220,220,220,1);z-index:2;box-sizing:border-box;}.tukdd6b:after,.tukdd6b:before{top:100%;left:50%;border:solid transparent;content:' ';height:0;width:0;position:absolute;pointer-events:none;}.tukdd6b:after{border-color:rgba(255,255,255,0);border-top-color:#fff;border-width:4px;margin-left:-4px;}.tukdd6b:before{border-color:rgba(221,221,221,0);border-top-color:#ddd;border-width:6px;margin-left:-6px;}
.s1o2cezu{display:inline-block;border-right:1px solid #ddd;height:24px;margin:0 0.5em;}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year-container {
  margin: 0.4rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.react-datepicker__year-container-text {
  display: inline-block;
  cursor: pointer;
  flex: 1 0 30%;
  width: 12px;
  padding: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
}

.PhoneInputCountryIcon {
	width: calc(1em * 1.5);
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: 1em;
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: 1em;
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: rgba(0,0,0,0.1);
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 1px rgba(0,0,0,0.5),
		inset 0 0 0 1px rgba(0,0,0,0.5);
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: 0.8;
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: 0.65;
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: 0.35em;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: 0.3em;
	width: var(--PhoneInputCountrySelectArrow-width);
	height: 0.3em;
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: 0.35em;
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: inherit;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: 1px;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: 1px;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	-webkit-transform: var(--PhoneInputCountrySelectArrow-transform);
	        transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: 0.45;
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: #03b2cb;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 1px #03b2cb,
		inset 0 0 0 1px #03b2cb;
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: #03b2cb;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}
@charset "UTF-8";
.intl-tel-input {
  position: relative;
  display: inline-block; }
  .intl-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .intl-tel-input .hide {
    display: none; }
  .intl-tel-input .v-hide {
    visibility: hidden; }
  .intl-tel-input input, .intl-tel-input input[type=text], .intl-tel-input input[type=tel] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0; }
  .intl-tel-input .flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px; }
    .intl-tel-input .flag-container .arrow {
      font-size: 6px;
      margin-left: 5px; }
      .intl-tel-input .flag-container .arrow.up:after {
        content: '▲'; }
      .intl-tel-input .flag-container .arrow.down:after {
        content: '▼'; }
  .intl-tel-input .selected-flag {
    z-index: 1;
    position: relative;
    width: 36px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .intl-tel-input .country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid #CCC;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .intl-tel-input .country-list .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #CCC; }
    .intl-tel-input .country-list.dropup {
      bottom: 100%;
      margin-bottom: -1px; }
    .intl-tel-input .country-list .flag-box {
      display: inline-block;
      width: 20px; }
    @media (max-width: 500px) {
      .intl-tel-input .country-list {
        white-space: normal; } }
    .intl-tel-input .country-list .country {
      padding: 5px 10px; }
      .intl-tel-input .country-list .country .dial-code {
        color: #999; }
    .intl-tel-input .country-list .country.highlight {
      background-color: rgba(0, 0, 0, 0.05); }
    .intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .dial-code {
      vertical-align: middle; }
    .intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name {
      margin-right: 6px; }
  .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0; }
  .intl-tel-input.allow-dropdown .flag-container {
    right: auto;
    left: 0;
    width: 100%; }
  .intl-tel-input.allow-dropdown .selected-flag {
    width: 46px; }
  .intl-tel-input.allow-dropdown .flag-container:hover {
    cursor: pointer; }
    .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
      background-color: rgba(0, 0, 0, 0.05); }
  .intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
    cursor: default; }
    .intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
      background-color: transparent; }
  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    background-color: rgba(0, 0, 0, 0.05);
    display: table; }
  .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    display: table-cell;
    vertical-align: middle;
    padding-left: 28px; }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=tel] {
    padding-left: 76px; }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag {
    width: 70px; }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel] {
    padding-left: 84px; }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag {
    width: 78px; }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel] {
    padding-left: 92px; }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
    width: 86px; }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=tel] {
    padding-left: 100px; }
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 .selected-flag {
    width: 94px; }
  .intl-tel-input.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px; }
    .intl-tel-input.iti-container:hover {
      cursor: pointer; }

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch; }
  .iti-mobile .intl-tel-input .country-list .country {
    padding: 10px 10px;
    line-height: 1.5em; }

.iti-flag {
  width: 20px; }
  .iti-flag.be {
    width: 18px; }
  .iti-flag.ch {
    width: 15px; }
  .iti-flag.mc {
    width: 19px; }
  .iti-flag.ne {
    width: 18px; }
  .iti-flag.np {
    width: 13px; }
  .iti-flag.va {
    width: 15px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .iti-flag {
      background-size: 5630px 15px; } }
  .iti-flag.ac {
    height: 10px;
    background-position: 0px 0px; }
  .iti-flag.ad {
    height: 14px;
    background-position: -22px 0px; }
  .iti-flag.ae {
    height: 10px;
    background-position: -44px 0px; }
  .iti-flag.af {
    height: 14px;
    background-position: -66px 0px; }
  .iti-flag.ag {
    height: 14px;
    background-position: -88px 0px; }
  .iti-flag.ai {
    height: 10px;
    background-position: -110px 0px; }
  .iti-flag.al {
    height: 15px;
    background-position: -132px 0px; }
  .iti-flag.am {
    height: 10px;
    background-position: -154px 0px; }
  .iti-flag.ao {
    height: 14px;
    background-position: -176px 0px; }
  .iti-flag.aq {
    height: 14px;
    background-position: -198px 0px; }
  .iti-flag.ar {
    height: 13px;
    background-position: -220px 0px; }
  .iti-flag.as {
    height: 10px;
    background-position: -242px 0px; }
  .iti-flag.at {
    height: 14px;
    background-position: -264px 0px; }
  .iti-flag.au {
    height: 10px;
    background-position: -286px 0px; }
  .iti-flag.aw {
    height: 14px;
    background-position: -308px 0px; }
  .iti-flag.ax {
    height: 13px;
    background-position: -330px 0px; }
  .iti-flag.az {
    height: 10px;
    background-position: -352px 0px; }
  .iti-flag.ba {
    height: 10px;
    background-position: -374px 0px; }
  .iti-flag.bb {
    height: 14px;
    background-position: -396px 0px; }
  .iti-flag.bd {
    height: 12px;
    background-position: -418px 0px; }
  .iti-flag.be {
    height: 15px;
    background-position: -440px 0px; }
  .iti-flag.bf {
    height: 14px;
    background-position: -460px 0px; }
  .iti-flag.bg {
    height: 12px;
    background-position: -482px 0px; }
  .iti-flag.bh {
    height: 12px;
    background-position: -504px 0px; }
  .iti-flag.bi {
    height: 12px;
    background-position: -526px 0px; }
  .iti-flag.bj {
    height: 14px;
    background-position: -548px 0px; }
  .iti-flag.bl {
    height: 14px;
    background-position: -570px 0px; }
  .iti-flag.bm {
    height: 10px;
    background-position: -592px 0px; }
  .iti-flag.bn {
    height: 10px;
    background-position: -614px 0px; }
  .iti-flag.bo {
    height: 14px;
    background-position: -636px 0px; }
  .iti-flag.bq {
    height: 14px;
    background-position: -658px 0px; }
  .iti-flag.br {
    height: 14px;
    background-position: -680px 0px; }
  .iti-flag.bs {
    height: 10px;
    background-position: -702px 0px; }
  .iti-flag.bt {
    height: 14px;
    background-position: -724px 0px; }
  .iti-flag.bv {
    height: 15px;
    background-position: -746px 0px; }
  .iti-flag.bw {
    height: 14px;
    background-position: -768px 0px; }
  .iti-flag.by {
    height: 10px;
    background-position: -790px 0px; }
  .iti-flag.bz {
    height: 14px;
    background-position: -812px 0px; }
  .iti-flag.ca {
    height: 10px;
    background-position: -834px 0px; }
  .iti-flag.cc {
    height: 10px;
    background-position: -856px 0px; }
  .iti-flag.cd {
    height: 15px;
    background-position: -878px 0px; }
  .iti-flag.cf {
    height: 14px;
    background-position: -900px 0px; }
  .iti-flag.cg {
    height: 14px;
    background-position: -922px 0px; }
  .iti-flag.ch {
    height: 15px;
    background-position: -944px 0px; }
  .iti-flag.ci {
    height: 14px;
    background-position: -961px 0px; }
  .iti-flag.ck {
    height: 10px;
    background-position: -983px 0px; }
  .iti-flag.cl {
    height: 14px;
    background-position: -1005px 0px; }
  .iti-flag.cm {
    height: 14px;
    background-position: -1027px 0px; }
  .iti-flag.cn {
    height: 14px;
    background-position: -1049px 0px; }
  .iti-flag.co {
    height: 14px;
    background-position: -1071px 0px; }
  .iti-flag.cp {
    height: 14px;
    background-position: -1093px 0px; }
  .iti-flag.cr {
    height: 12px;
    background-position: -1115px 0px; }
  .iti-flag.cu {
    height: 10px;
    background-position: -1137px 0px; }
  .iti-flag.cv {
    height: 12px;
    background-position: -1159px 0px; }
  .iti-flag.cw {
    height: 14px;
    background-position: -1181px 0px; }
  .iti-flag.cx {
    height: 10px;
    background-position: -1203px 0px; }
  .iti-flag.cy {
    height: 14px;
    background-position: -1225px 0px; }
  .iti-flag.cz {
    height: 14px;
    background-position: -1247px 0px; }
  .iti-flag.de {
    height: 12px;
    background-position: -1269px 0px; }
  .iti-flag.dg {
    height: 10px;
    background-position: -1291px 0px; }
  .iti-flag.dj {
    height: 14px;
    background-position: -1313px 0px; }
  .iti-flag.dk {
    height: 15px;
    background-position: -1335px 0px; }
  .iti-flag.dm {
    height: 10px;
    background-position: -1357px 0px; }
  .iti-flag.do {
    height: 13px;
    background-position: -1379px 0px; }
  .iti-flag.dz {
    height: 14px;
    background-position: -1401px 0px; }
  .iti-flag.ea {
    height: 14px;
    background-position: -1423px 0px; }
  .iti-flag.ec {
    height: 14px;
    background-position: -1445px 0px; }
  .iti-flag.ee {
    height: 13px;
    background-position: -1467px 0px; }
  .iti-flag.eg {
    height: 14px;
    background-position: -1489px 0px; }
  .iti-flag.eh {
    height: 10px;
    background-position: -1511px 0px; }
  .iti-flag.er {
    height: 10px;
    background-position: -1533px 0px; }
  .iti-flag.es {
    height: 14px;
    background-position: -1555px 0px; }
  .iti-flag.et {
    height: 10px;
    background-position: -1577px 0px; }
  .iti-flag.eu {
    height: 14px;
    background-position: -1599px 0px; }
  .iti-flag.fi {
    height: 12px;
    background-position: -1621px 0px; }
  .iti-flag.fj {
    height: 10px;
    background-position: -1643px 0px; }
  .iti-flag.fk {
    height: 10px;
    background-position: -1665px 0px; }
  .iti-flag.fm {
    height: 11px;
    background-position: -1687px 0px; }
  .iti-flag.fo {
    height: 15px;
    background-position: -1709px 0px; }
  .iti-flag.fr {
    height: 14px;
    background-position: -1731px 0px; }
  .iti-flag.ga {
    height: 15px;
    background-position: -1753px 0px; }
  .iti-flag.gb {
    height: 10px;
    background-position: -1775px 0px; }
  .iti-flag.gd {
    height: 12px;
    background-position: -1797px 0px; }
  .iti-flag.ge {
    height: 14px;
    background-position: -1819px 0px; }
  .iti-flag.gf {
    height: 14px;
    background-position: -1841px 0px; }
  .iti-flag.gg {
    height: 14px;
    background-position: -1863px 0px; }
  .iti-flag.gh {
    height: 14px;
    background-position: -1885px 0px; }
  .iti-flag.gi {
    height: 10px;
    background-position: -1907px 0px; }
  .iti-flag.gl {
    height: 14px;
    background-position: -1929px 0px; }
  .iti-flag.gm {
    height: 14px;
    background-position: -1951px 0px; }
  .iti-flag.gn {
    height: 14px;
    background-position: -1973px 0px; }
  .iti-flag.gp {
    height: 14px;
    background-position: -1995px 0px; }
  .iti-flag.gq {
    height: 14px;
    background-position: -2017px 0px; }
  .iti-flag.gr {
    height: 14px;
    background-position: -2039px 0px; }
  .iti-flag.gs {
    height: 10px;
    background-position: -2061px 0px; }
  .iti-flag.gt {
    height: 13px;
    background-position: -2083px 0px; }
  .iti-flag.gu {
    height: 11px;
    background-position: -2105px 0px; }
  .iti-flag.gw {
    height: 10px;
    background-position: -2127px 0px; }
  .iti-flag.gy {
    height: 12px;
    background-position: -2149px 0px; }
  .iti-flag.hk {
    height: 14px;
    background-position: -2171px 0px; }
  .iti-flag.hm {
    height: 10px;
    background-position: -2193px 0px; }
  .iti-flag.hn {
    height: 10px;
    background-position: -2215px 0px; }
  .iti-flag.hr {
    height: 10px;
    background-position: -2237px 0px; }
  .iti-flag.ht {
    height: 12px;
    background-position: -2259px 0px; }
  .iti-flag.hu {
    height: 10px;
    background-position: -2281px 0px; }
  .iti-flag.ic {
    height: 14px;
    background-position: -2303px 0px; }
  .iti-flag.id {
    height: 14px;
    background-position: -2325px 0px; }
  .iti-flag.ie {
    height: 10px;
    background-position: -2347px 0px; }
  .iti-flag.il {
    height: 15px;
    background-position: -2369px 0px; }
  .iti-flag.im {
    height: 10px;
    background-position: -2391px 0px; }
  .iti-flag.in {
    height: 14px;
    background-position: -2413px 0px; }
  .iti-flag.io {
    height: 10px;
    background-position: -2435px 0px; }
  .iti-flag.iq {
    height: 14px;
    background-position: -2457px 0px; }
  .iti-flag.ir {
    height: 12px;
    background-position: -2479px 0px; }
  .iti-flag.is {
    height: 15px;
    background-position: -2501px 0px; }
  .iti-flag.it {
    height: 14px;
    background-position: -2523px 0px; }
  .iti-flag.je {
    height: 12px;
    background-position: -2545px 0px; }
  .iti-flag.jm {
    height: 10px;
    background-position: -2567px 0px; }
  .iti-flag.jo {
    height: 10px;
    background-position: -2589px 0px; }
  .iti-flag.jp {
    height: 14px;
    background-position: -2611px 0px; }
  .iti-flag.ke {
    height: 14px;
    background-position: -2633px 0px; }
  .iti-flag.kg {
    height: 12px;
    background-position: -2655px 0px; }
  .iti-flag.kh {
    height: 13px;
    background-position: -2677px 0px; }
  .iti-flag.ki {
    height: 10px;
    background-position: -2699px 0px; }
  .iti-flag.km {
    height: 12px;
    background-position: -2721px 0px; }
  .iti-flag.kn {
    height: 14px;
    background-position: -2743px 0px; }
  .iti-flag.kp {
    height: 10px;
    background-position: -2765px 0px; }
  .iti-flag.kr {
    height: 14px;
    background-position: -2787px 0px; }
  .iti-flag.kw {
    height: 10px;
    background-position: -2809px 0px; }
  .iti-flag.ky {
    height: 10px;
    background-position: -2831px 0px; }
  .iti-flag.kz {
    height: 10px;
    background-position: -2853px 0px; }
  .iti-flag.la {
    height: 14px;
    background-position: -2875px 0px; }
  .iti-flag.lb {
    height: 14px;
    background-position: -2897px 0px; }
  .iti-flag.lc {
    height: 10px;
    background-position: -2919px 0px; }
  .iti-flag.li {
    height: 12px;
    background-position: -2941px 0px; }
  .iti-flag.lk {
    height: 10px;
    background-position: -2963px 0px; }
  .iti-flag.lr {
    height: 11px;
    background-position: -2985px 0px; }
  .iti-flag.ls {
    height: 14px;
    background-position: -3007px 0px; }
  .iti-flag.lt {
    height: 12px;
    background-position: -3029px 0px; }
  .iti-flag.lu {
    height: 12px;
    background-position: -3051px 0px; }
  .iti-flag.lv {
    height: 10px;
    background-position: -3073px 0px; }
  .iti-flag.ly {
    height: 10px;
    background-position: -3095px 0px; }
  .iti-flag.ma {
    height: 14px;
    background-position: -3117px 0px; }
  .iti-flag.mc {
    height: 15px;
    background-position: -3139px 0px; }
  .iti-flag.md {
    height: 10px;
    background-position: -3160px 0px; }
  .iti-flag.me {
    height: 10px;
    background-position: -3182px 0px; }
  .iti-flag.mf {
    height: 14px;
    background-position: -3204px 0px; }
  .iti-flag.mg {
    height: 14px;
    background-position: -3226px 0px; }
  .iti-flag.mh {
    height: 11px;
    background-position: -3248px 0px; }
  .iti-flag.mk {
    height: 10px;
    background-position: -3270px 0px; }
  .iti-flag.ml {
    height: 14px;
    background-position: -3292px 0px; }
  .iti-flag.mm {
    height: 14px;
    background-position: -3314px 0px; }
  .iti-flag.mn {
    height: 10px;
    background-position: -3336px 0px; }
  .iti-flag.mo {
    height: 14px;
    background-position: -3358px 0px; }
  .iti-flag.mp {
    height: 10px;
    background-position: -3380px 0px; }
  .iti-flag.mq {
    height: 14px;
    background-position: -3402px 0px; }
  .iti-flag.mr {
    height: 14px;
    background-position: -3424px 0px; }
  .iti-flag.ms {
    height: 10px;
    background-position: -3446px 0px; }
  .iti-flag.mt {
    height: 14px;
    background-position: -3468px 0px; }
  .iti-flag.mu {
    height: 14px;
    background-position: -3490px 0px; }
  .iti-flag.mv {
    height: 14px;
    background-position: -3512px 0px; }
  .iti-flag.mw {
    height: 14px;
    background-position: -3534px 0px; }
  .iti-flag.mx {
    height: 12px;
    background-position: -3556px 0px; }
  .iti-flag.my {
    height: 10px;
    background-position: -3578px 0px; }
  .iti-flag.mz {
    height: 14px;
    background-position: -3600px 0px; }
  .iti-flag.na {
    height: 14px;
    background-position: -3622px 0px; }
  .iti-flag.nc {
    height: 10px;
    background-position: -3644px 0px; }
  .iti-flag.ne {
    height: 15px;
    background-position: -3666px 0px; }
  .iti-flag.nf {
    height: 10px;
    background-position: -3686px 0px; }
  .iti-flag.ng {
    height: 10px;
    background-position: -3708px 0px; }
  .iti-flag.ni {
    height: 12px;
    background-position: -3730px 0px; }
  .iti-flag.nl {
    height: 14px;
    background-position: -3752px 0px; }
  .iti-flag.no {
    height: 15px;
    background-position: -3774px 0px; }
  .iti-flag.np {
    height: 15px;
    background-position: -3796px 0px; }
  .iti-flag.nr {
    height: 10px;
    background-position: -3811px 0px; }
  .iti-flag.nu {
    height: 10px;
    background-position: -3833px 0px; }
  .iti-flag.nz {
    height: 10px;
    background-position: -3855px 0px; }
  .iti-flag.om {
    height: 10px;
    background-position: -3877px 0px; }
  .iti-flag.pa {
    height: 14px;
    background-position: -3899px 0px; }
  .iti-flag.pe {
    height: 14px;
    background-position: -3921px 0px; }
  .iti-flag.pf {
    height: 14px;
    background-position: -3943px 0px; }
  .iti-flag.pg {
    height: 15px;
    background-position: -3965px 0px; }
  .iti-flag.ph {
    height: 10px;
    background-position: -3987px 0px; }
  .iti-flag.pk {
    height: 14px;
    background-position: -4009px 0px; }
  .iti-flag.pl {
    height: 13px;
    background-position: -4031px 0px; }
  .iti-flag.pm {
    height: 14px;
    background-position: -4053px 0px; }
  .iti-flag.pn {
    height: 10px;
    background-position: -4075px 0px; }
  .iti-flag.pr {
    height: 14px;
    background-position: -4097px 0px; }
  .iti-flag.ps {
    height: 10px;
    background-position: -4119px 0px; }
  .iti-flag.pt {
    height: 14px;
    background-position: -4141px 0px; }
  .iti-flag.pw {
    height: 13px;
    background-position: -4163px 0px; }
  .iti-flag.py {
    height: 11px;
    background-position: -4185px 0px; }
  .iti-flag.qa {
    height: 8px;
    background-position: -4207px 0px; }
  .iti-flag.re {
    height: 14px;
    background-position: -4229px 0px; }
  .iti-flag.ro {
    height: 14px;
    background-position: -4251px 0px; }
  .iti-flag.rs {
    height: 14px;
    background-position: -4273px 0px; }
  .iti-flag.ru {
    height: 14px;
    background-position: -4295px 0px; }
  .iti-flag.rw {
    height: 14px;
    background-position: -4317px 0px; }
  .iti-flag.sa {
    height: 14px;
    background-position: -4339px 0px; }
  .iti-flag.sb {
    height: 10px;
    background-position: -4361px 0px; }
  .iti-flag.sc {
    height: 10px;
    background-position: -4383px 0px; }
  .iti-flag.sd {
    height: 10px;
    background-position: -4405px 0px; }
  .iti-flag.se {
    height: 13px;
    background-position: -4427px 0px; }
  .iti-flag.sg {
    height: 14px;
    background-position: -4449px 0px; }
  .iti-flag.sh {
    height: 10px;
    background-position: -4471px 0px; }
  .iti-flag.si {
    height: 10px;
    background-position: -4493px 0px; }
  .iti-flag.sj {
    height: 15px;
    background-position: -4515px 0px; }
  .iti-flag.sk {
    height: 14px;
    background-position: -4537px 0px; }
  .iti-flag.sl {
    height: 14px;
    background-position: -4559px 0px; }
  .iti-flag.sm {
    height: 15px;
    background-position: -4581px 0px; }
  .iti-flag.sn {
    height: 14px;
    background-position: -4603px 0px; }
  .iti-flag.so {
    height: 14px;
    background-position: -4625px 0px; }
  .iti-flag.sr {
    height: 14px;
    background-position: -4647px 0px; }
  .iti-flag.ss {
    height: 10px;
    background-position: -4669px 0px; }
  .iti-flag.st {
    height: 10px;
    background-position: -4691px 0px; }
  .iti-flag.sv {
    height: 12px;
    background-position: -4713px 0px; }
  .iti-flag.sx {
    height: 14px;
    background-position: -4735px 0px; }
  .iti-flag.sy {
    height: 14px;
    background-position: -4757px 0px; }
  .iti-flag.sz {
    height: 14px;
    background-position: -4779px 0px; }
  .iti-flag.ta {
    height: 10px;
    background-position: -4801px 0px; }
  .iti-flag.tc {
    height: 10px;
    background-position: -4823px 0px; }
  .iti-flag.td {
    height: 14px;
    background-position: -4845px 0px; }
  .iti-flag.tf {
    height: 14px;
    background-position: -4867px 0px; }
  .iti-flag.tg {
    height: 13px;
    background-position: -4889px 0px; }
  .iti-flag.th {
    height: 14px;
    background-position: -4911px 0px; }
  .iti-flag.tj {
    height: 10px;
    background-position: -4933px 0px; }
  .iti-flag.tk {
    height: 10px;
    background-position: -4955px 0px; }
  .iti-flag.tl {
    height: 10px;
    background-position: -4977px 0px; }
  .iti-flag.tm {
    height: 14px;
    background-position: -4999px 0px; }
  .iti-flag.tn {
    height: 14px;
    background-position: -5021px 0px; }
  .iti-flag.to {
    height: 10px;
    background-position: -5043px 0px; }
  .iti-flag.tr {
    height: 14px;
    background-position: -5065px 0px; }
  .iti-flag.tt {
    height: 12px;
    background-position: -5087px 0px; }
  .iti-flag.tv {
    height: 10px;
    background-position: -5109px 0px; }
  .iti-flag.tw {
    height: 14px;
    background-position: -5131px 0px; }
  .iti-flag.tz {
    height: 14px;
    background-position: -5153px 0px; }
  .iti-flag.ua {
    height: 14px;
    background-position: -5175px 0px; }
  .iti-flag.ug {
    height: 14px;
    background-position: -5197px 0px; }
  .iti-flag.um {
    height: 11px;
    background-position: -5219px 0px; }
  .iti-flag.us {
    height: 11px;
    background-position: -5241px 0px; }
  .iti-flag.uy {
    height: 14px;
    background-position: -5263px 0px; }
  .iti-flag.uz {
    height: 10px;
    background-position: -5285px 0px; }
  .iti-flag.va {
    height: 15px;
    background-position: -5307px 0px; }
  .iti-flag.vc {
    height: 14px;
    background-position: -5324px 0px; }
  .iti-flag.ve {
    height: 14px;
    background-position: -5346px 0px; }
  .iti-flag.vg {
    height: 10px;
    background-position: -5368px 0px; }
  .iti-flag.vi {
    height: 14px;
    background-position: -5390px 0px; }
  .iti-flag.vn {
    height: 14px;
    background-position: -5412px 0px; }
  .iti-flag.vu {
    height: 12px;
    background-position: -5434px 0px; }
  .iti-flag.wf {
    height: 14px;
    background-position: -5456px 0px; }
  .iti-flag.ws {
    height: 10px;
    background-position: -5478px 0px; }
  .iti-flag.xk {
    height: 15px;
    background-position: -5500px 0px; }
  .iti-flag.ye {
    height: 14px;
    background-position: -5522px 0px; }
  .iti-flag.yt {
    height: 14px;
    background-position: -5544px 0px; }
  .iti-flag.za {
    height: 14px;
    background-position: -5566px 0px; }
  .iti-flag.zm {
    height: 14px;
    background-position: -5588px 0px; }
  .iti-flag.zw {
    height: 10px;
    background-position: -5610px 0px; }

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url(/static/media/flags.5c58d0e1.png);
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .iti-flag {
      background-image: url(/static/media/flags@2x.27670c53.png); } }

.iti-flag.np {
  background-color: transparent; }

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
/* overrides extra padding on button elements in Firefox */
@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  25% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }
@keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  25% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  transition: all 0.3s ease-out; }

.introjs-showElement {
  z-index: 9999999 !important; }

tr.introjs-showElement > td {
  z-index: 9999999 !important;
  position: relative; }

tr.introjs-showElement > th {
  z-index: 9999999 !important;
  position: relative; }

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  filter: alpha(opacity=0); }

.introjs-relativePosition {
  position: relative; }

.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  transition: all 0.3s ease-out; }
  .introjs-helperLayer * {
    box-sizing: content-box; }
    .introjs-helperLayer *:before {
      box-sizing: content-box; }
    .introjs-helperLayer *:after {
      box-sizing: content-box; }

.introjs-tooltipReferenceLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out; }
  .introjs-tooltipReferenceLayer * {
    font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif; }

.introjs-helperNumberLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute; }

.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #ffffff; }

.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #ffffff; }

.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #ffffff; }

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #ffffff; }

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #ffffff; }

.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #ffffff; }

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #ffffff; }

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #ffffff; }

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #ffffff; }

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #ffffff; }

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #ffffff;
  min-width: 250px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  transition: opacity 0.1s ease-out; }

.introjs-tooltiptext {
  padding: 20px; }

.introjs-tooltip-title {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  float: left;
  line-height: 32px; }

.introjs-tooltip-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px; }
  .introjs-tooltip-header:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: right;
  white-space: nowrap; }
  .introjs-tooltipbuttons:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  text-shadow: 1px 1px 0 #ffffff;
  font-size: 14px;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: #f4f4f4;
  border-radius: 0.2em;
  zoom: 1;
  *display: inline; }
  .introjs-button:hover {
    outline: none;
    text-decoration: none;
    border-color: #9e9e9e;
    background-color: #e0e0e0;
    color: #212121; }
  .introjs-button:focus {
    outline: none;
    text-decoration: none;
    background-color: #eeeeee;
    box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5);
    border: 1px solid #616161;
    color: #212121; }
  .introjs-button:active {
    outline: none;
    text-decoration: none;
    background-color: #e0e0e0;
    border-color: #9e9e9e;
    color: #212121; }
  .introjs-button::-moz-focus-inner {
    padding: 0;
    border: 0; }

.introjs-skipbutton {
  box-sizing: content-box;
  color: #616161;
  float: right;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  padding: 7px 10px; }
  .introjs-skipbutton:hover, .introjs-skipbutton:focus {
    color: #212121;
    outline: none;
    text-decoration: none; }

.introjs-prevbutton {
  float: left; }

.introjs-nextbutton {
  float: right; }

.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none; }
  .introjs-disabled:hover, .introjs-disabled:focus {
    color: #9e9e9e;
    border-color: #bdbdbd;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none; }

.introjs-hidden {
  display: none; }

.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }
  .introjs-bullets ul {
    box-sizing: content-box;
    clear: both;
    margin: 0 auto 0;
    padding: 0;
    display: inline-block; }
    .introjs-bullets ul li {
      box-sizing: content-box;
      list-style: none;
      float: left;
      margin: 0 2px; }
      .introjs-bullets ul li a {
        transition: width 0.1s ease-in;
        box-sizing: content-box;
        display: block;
        width: 6px;
        height: 6px;
        background: #ccc;
        border-radius: 10px;
        text-decoration: none;
        cursor: pointer; }
        .introjs-bullets ul li a:hover, .introjs-bullets ul li a:focus {
          width: 15px;
          background: #999;
          text-decoration: none;
          outline: none; }
      .introjs-bullets ul li a.active {
        width: 15px;
        background: #999; }

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0; }

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c; }

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%; }

.introjs-fixedTooltip {
  position: fixed; }

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer; }
  .introjs-hint:focus {
    border: 0;
    outline: 0; }
  .introjs-hint:hover > .introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, 0.57); }

.introjs-hidehint {
  display: none; }

.introjs-fixedhint {
  position: fixed; }

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out; }

.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
          animation: none; }

.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
          animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0; }

